<template>
  <Layout class="GameLibrary">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="userId">
          <el-input
            v-model="formData.userId"
            clearable
            placeholder="UID"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="typeId">
          <el-select
            v-model="formData.typeId"
            clearable
            placeholder="请选择问题类型"
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
        <el-form-item style="margin-left:50px">
          <el-button
            v-if="!isEcharts"
            type="primary"
            @click="changeEcharts"
          >
            查看图表形式
          </el-button>
          <el-button
            v-if="isEcharts"
            type="primary"
            @click="changeEcharts"
          >
            查看表格形式
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <echartList
      v-if="isEcharts"
      ref="echarts"
      :form-data="formData"
      :status-list="statusList"
    />
    <el-table
      v-if="isTableList && !isEcharts"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="UID"
        prop="userIdStr"
      />
      <el-table-column label="问题类型展示">
        <template #default="{ row }">
          {{ statusMap[row.typeId] || "无" }}
        </template>
      </el-table-column>
      <el-table-column
        label="客户发送时间"
        prop="createTime"
      />

      <el-table-column
        label="用户状态"
        width="100px"
      >
        <template #default="{ row }">
          {{ row.status === 1 ? "正常" : "禁言" }}
        </template>
      </el-table-column>
      <el-table-column
        label="详细内容"
        prop="content"
      />
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button
            type="text"
            @click="goMessageReply(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.status === 1"
            type="text"
            @click="goEditstatus(row, 2)"
          >
            禁用
          </el-button>
          <el-button
            v-else
            type="text"
            @click="goEditstatus(row, 1)"
          >
            解禁
          </el-button>
          <el-button
            v-if="row.isTop === 0"
            type="text"
            @click="Topping(row, 1)"
          >
            置顶
          </el-button>
          <el-button
            v-else
            type="text"
            @click="Topping(row, 2)"
          >
            取消置顶
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="回复内容">
        <template #default="{ row }">
          <div style="margin-left:10px">
            {{ row.replyContent }}
          </div>
          <div style="display:flex">
            <img
              v-for="(t, i) of row.replyImg"
              :key="i"
              style="width: 50px;margin-left:10px"
              :src="t"
              alt=""
              @click="checkimg(t)"
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="isTableList && !isEcharts"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryFeedbackListList"
    />
    <div v-if="!isTableList" />
    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="800px"
      style="text-align: center;"
      @close="handleDialogClose"
    >
      <img
        style="max-width: 700px;"
        :src="imgUrl"
        alt=""
      >
    </el-dialog>
  </Layout>
</template>

<script>
// import { format } from 'fecha'
import echartList from './echarts/index.vue'
import {
  getFeedbackList,
  getFeedbackTypeList,
  setFeedbackTop,
  forbiddenFeedbackUser
} from '@/api/Userfeedback.js'
// const getYesterday = () => {
//   return [format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
//   // const hours = new Date().getHours()
//   // // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
//   // const days = hours > 9 ? 1 : 2
//   // const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
//   // return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
// }
// const getYesterday = () => {
//   return [format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
//   // const hours = new Date().getHours()
//   // // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
//   // const days = hours > 9 ? 1 : 2
//   // const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
//   // return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
// }
export default {
  name: 'FeedbackSystem',
  beforeRouteLeave (to, from, next) {
    if (document.querySelector('.main-view')) {
      sessionStorage.FeedbackSystemScrll = document.querySelector(
        '.main-view'
      ).scrollTop
    }
    next()
  },
  components: { echartList },
  data () {
    return {
      isTableList: true,
      showDialog: false,
      isEcharts: false,
      dialogTile: '查看图片',
      imgUrl: '',
      statisticsLoading: false,
      statisticsInfo: {},
      statusList: [
        { name: 'All', value: 1 },
        { name: 'Lag and frame drop', value: 2 },
        { name: 'Controller/Keyboard&Mouse', value: 3 },
        { name: 'Crash、blank screen', value: 4 },
        { name: 'Faile to recharge', value: 5 },
        { name: 'Others', value: 6 }
      ],
      statusMap: {
        1: '未编辑',
        2: '已编辑'
      },
      formData: {
        dateRange: null,
        pageNum: 1,
        userId: '',
        typeId: '',
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          // const hours = new Date().getHours()
          // const days = hours > 9 ? 1 : 2- days * 24 * 60 * 60 * 1000
          const dateTime = time.getTime()
          return (
            dateTime < new Date('2020-12-31').getTime() ||
            dateTime > new Date(Date.now()).getTime()
          )
        }
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      newGameVisible: false,
      newGameLoading: false,
      newGameForm: {
        platform: 'steam',
        sourceId: ''
      }
    }
  },
  activated () {
    this.$nextTick(() => {
      if (document.querySelector('.main-view')) {
        document.querySelector('.main-view').scrollTo({
          top: sessionStorage.FeedbackSystemScrll,
          left: 0,
          behavior: 'auto'
        })
      }
    })
  },
  created () {
    this.handleSearch()
    this.queryFeedbackTypes()
  },
  methods: {
    checkimg (img) {
      this.imgUrl = img
      this.showDialog = true
    },
    handleDialogClose () {
      this.imgUrl = ''
    },
    changeEcharts () {
      this.isEcharts = !this.isEcharts
    },
    handleSearch () {
      if (this.isEcharts) {
        if (this.formData.dateRange) {
          const [startTime, endTime] = this.formData.dateRange
          this.formData.timeStart = startTime
          this.formData.timeEnd = endTime
        } else {
          delete this.formData.timeStart
          delete this.formData.timeEnd
        }
        this.$refs.echarts.nuxtList()
      } else {
        this.formData.pageNum = 1
        this.queryFeedbackListList()
      }
    },
    queryFeedbackListList () {
      if (this.formData.dateRange) {
        const [startTime, endTime] = this.formData.dateRange
        this.formData.timeStart = startTime
        this.formData.timeEnd = endTime
      } else {
        delete this.formData.timeStart
        delete this.formData.timeEnd
      }
      // const [startTime, endTime] = this.formData.dateRange
      // this.formData.timeStart = startTime
      // this.formData.timeEnd = endTime
      this.listInfo.loading = true
      getFeedbackList(this.formData)
        .then(res => {
          if (res.code === 200) {
            if (res.data.list) {
              for (var i = 0; i < res.data.list.length; i++) {
                if (res.data.list[i].replyImg) {
                  res.data.list[i].replyImg = res.data.list[i].replyImg.split()
                } else {
                  res.data.list[i].replyImg = []
                }
              }
            }
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          delete this.formData.timeStart
          delete this.formData.timeEnd
          this.listInfo.loading = false
        })
    },
    queryFeedbackTypes () {
      this.listInfo.loading = true
      getFeedbackTypeList({ category: 1 })
        .then(res => {
          this.statusList = res.map(({ id, name }) => ({ name: name, value: id }))
          this.statusMap = res.reduce((obj, cur) => ({ ...obj, [cur.id]: cur.name }), {})
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleCreateGame () {
      this.newGameForm.sourceId = ''
      this.newGameVisible = true
    },
    goMessageReply (row) {
      const route = {
        name: 'FeedbackSystemEdit',
        query: {
          userId: row.userIdStr,
          id: row.id
        }
      }
      window.open(this.$router.resolve(route).href, '_blank')
    },
    goEditstatus (row, num) {
      this.listInfo.loading = true
      forbiddenFeedbackUser({
        userId: row.userIdStr,
        status: num,
        adminId: this.$store.state.user.user.id
      })
        .then(res => {
          if (res.code === 200) {
            this.queryFeedbackListList()
            if (num === 1) {
              this.$message.success('解禁成功')
            } else {
              this.$message.success('禁用成功')
            }
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    Topping (row, num) {
      this.listInfo.loading = true
      if (num === 1) {
        setFeedbackTop({ id: row.id, setTop: true })
          .then(res => {
            if (res.code === 200) {
              this.queryFeedbackListList()
              this.$message.success('置顶成功')
            }
          })
          .finally(() => {
            this.listInfo.loading = false
          })
      } else {
        setFeedbackTop({ id: row.id, setTop: false })
          .then(res => {
            if (res.code === 200) {
              this.queryFeedbackListList()
              this.$message.success('取消置顶成功')
            }
          })
          .finally(() => {
            this.listInfo.loading = false
          })
      }
    }
  }
}
</script>

<style scoped lang="less">
.GameLibrary {
  .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;

    .items {
      width: 210px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .num {
        font-size: 26px;
        margin-bottom: 10px;

        .unit {
          font-size: 14px;
        }
      }

      .name {
        font-size: 12px;
      }
    }
  }
}
</style>
